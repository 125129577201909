@use '../../../../components/includes' as *;

.about_this_video_main_column {
  grid-area: aboutThisVideo;
}

.content_metadata {
  grid-area: contentMetaData;
}

.how_to_watch {
  grid-area: howToWatch;
}

.about_this_video_tab {
  display: grid;
  grid-gap: var(--responsive-row-gap) var(--responsive-col-gap);
  grid-template-areas:
    'aboutThisVideo'
    'contentMetaData'
    'howToWatch';

  @include breakpoint($sm) {
    grid-template-areas: 'howToWatch contentMetaData';
    grid-template-columns: 1fr 270px;
  }

  @include breakpoint($md) {
    grid-template-areas:
      'aboutThisVideo  contentMetaData'
      'howToWatch      contentMetaData';
    grid-template-columns: 2fr 1fr;
  }
}

.about_this_video_tab_container {
  display: flex;
  flex-direction: column;
  gap: var(--responsive-row-gap);
}

.about_this_video_tab_lower_content {
  // if we have more than two children, separate them
  > * + * {
    margin-block-start: var(--responsive-row-gap);
  }

  // funky way to get the hightlight to be full width
  > div[class*='highlight'] {
    width: calc(100% + var(--spacing-inline) * 2);
    margin-inline: calc(-1 * var(--spacing-inline));
  }
}
